import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import SettingsSidebar from "./SettingsSidebar/SettingsSidebar";
import "./settings.css";
import "../CSV/csv.css";
// import '../../global.css';
import {
  settingsRoutes,
  settingsBaseUrl,
  projectURL,
  integrationURL,
  dataImportURL,
  usersRolesURL,
  companyDetail,
  leadURL,
  automationUrl,
  listingUrls,
  plusAppURL,
  learningCentreURL,
  MentorURL,
  brokerURL,
  marketingURL,
  customerNewsURL
} from "../../routes/settings.routes";
import RenderComponent from "../../routes/RenderComponent";
import { getAuthRolePermissions } from "../../services/private/company.service";
import Consumer from '../../helpers/context';
import { checkIsSomeItemAvailableInList } from "../../helpers/helpers";
import useDocumentTitle from "../../custom hooks/useDocumentTitile";

export default function SettingsRouter() {
  const [settingURLs, setSettingURLs] = useState(settingsRoutes);
  const [integrationPermission, setIntegrationPermission] = useState();
  const [projectPermission, setProjectPermission] = useState();
  const [importPermission, setImportPermission] = useState();

  const settingRef = useRef();
  const ROLE_NAME = localStorage.getItem('role');

  let { userProfile, allowedPermissions, isRolesModule } = useContext(Consumer);

  useDocumentTitle('Settings');

  const getUsersAuthRolePermissions = () => {
    getAuthRolePermissions().then((res) => {
      let urls = [...settingURLs, { ...companyDetail }, { ...usersRolesURL }, { ...leadURL }, { ...automationUrl }, { ...listingUrls }]
      res.data.data.map((role) => {

        // if (role.module === "projects") {
        //   setProjectPermission(role.permissions);
        //   if (role.permissions?.includes("read")) {
        //     urls = [...urls, { ...projectURL }];
        //   }
        // }
        if (role.module === "import_leads") {
          setImportPermission(role.permissions);
          if (
            role.permissions?.includes("bulk_insert") ||
            role.permissions?.includes("csv_export")
          ) {
            urls = [...urls, { ...dataImportURL }];
          }
        }
        if (role.module === "integration") {
          setIntegrationPermission(role.permissions);
          if (role.permissions?.includes("read")) {
            urls = [...urls, { ...integrationURL }];
          }
        }
      });
      setSettingURLs([...urls])
    });
  };

  const getUrls = () => {
    let urls = [...settingURLs]
    // if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'create_project', 'edit_project', 'view_all_projects'])) {
    //   urls = [...urls, { ...projectURL }];
    // }
    if (checkIsSomeItemAvailableInList(allowedPermissions, ['import_leads', 'export_leads_without_approval'])) {
      urls = [...urls, { ...dataImportURL }];
    }

    if (ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('manage_automation')) {
      urls = [...urls, { ...automationUrl }]
    }

    if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_users', 'view_roles', 'create_role', 'add_new_user'])) {
      urls = [...urls, { ...usersRolesURL }];
    }
    // Uncomment this code for whatsapp and customer module
    //{ ...marketingURL } add this for whats app
    // if (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) {
    //   urls = [...urls, { ...customerNewsURL }]
    // }

    // urls = [...urls, { ...brokerURL }]
    // if (userProfile?.allowed_modules?.includes('plus_app_management') && allowedPermissions?.includes('plus_app_manage_users')) {
    //   urls = [...urls, { ...plusAppURL }]
    // }
    if (userProfile?.allowed_modules?.includes('learning_center_management') && allowedPermissions?.includes('learning_center_management')) {
      urls = [...urls, { ...learningCentreURL }, { ...MentorURL }, { ...plusAppURL }]
    }
    if (checkIsSomeItemAvailableInList(allowedPermissions, ['lead_duplication', 'lead_routing'])) {
      urls = [...urls, { ...leadURL }];
    }
    if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_marketing_agency', 'create_marketing_agency', 'edit_company_detail'])) {
      urls = [...urls, { ...companyDetail }];
    }
    if (checkIsSomeItemAvailableInList(allowedPermissions, ['create_fb_integration', 'create_landingpage_integration', 'create_housing_integration', 'create_99acres_integration', 'create_magicbricks_integration'])) {
      urls = [...urls, { ...integrationURL }];
    }
    if (userProfile?.allowed_modules?.includes('project_listing') && allowedPermissions.includes('project_listing_manage')) {
      urls = [...urls, { ...listingUrls }];
    }
    setSettingURLs([...urls])
  }

  useEffect(() => {
    if (!isRolesModule) {
      getUsersAuthRolePermissions()
    } else {
      getUrls();
    }

  }, [allowedPermissions])

  const handleMouseClick = (e) => {
    if (settingRef.current && !settingRef.current.contains(e.target)) {
      const el = document.getElementById("main-container");
      if (el.classList.contains("open-sidebar")) {
        el.classList.remove("open-sidebar");
        document.getElementById('hamburger_icon').style.display = 'block';
        document.getElementById('close_icon').style.display = 'none';
      }
    }
  };

  useEffect(() => {
    // getUsersAuthRolePermissions();
    document.addEventListener("mousedown", handleMouseClick);
    const el = document.getElementById("main-container");
    el.classList.add("user-settings");

    return () => {
      document.removeEventListener("mousedown", handleMouseClick);
      el.classList.remove("user-settings");
      if (el.classList.contains("open-sidebar")) {
        el.classList.remove("open-sidebar");
      }
    };
  }, []);

  const closeSideBar = useCallback(() => {
    const el = document.getElementById("main-container");
    el.classList.remove("open-sidebar");
    document.getElementById('hamburger_icon').style.display = 'block';
    document.getElementById('close_icon').style.display = 'none';
  }, []);

  return (
    <div
      className={`main-section position-relative d-flex p-0 flex-wrap `}
    >
      <div className="inport-part d-flex flex-fill">
        <div className="setting-menu d-flex flex-column" ref={settingRef}>
          <h2 className="fz18 fw-po-medium d-md-block d-none black mb-0 ">
            Settings
          </h2>
          <div className="d-md-none d-flex align-items-center justify-content-between mb-18">
            <h2 className="fz18 fw-po-medium black mb-0 pb-0">Settings</h2>
          </div>
          {/* Sidebar */}
          <SettingsSidebar
            settingsRoutes={settingURLs}
            closeSideBar={closeSideBar}
            importPermission={importPermission}
          />
        </div>
        <div className="data-import d-flex align-items-start justify-content-start flex-column position-relative">
          <RenderComponent routes={settingURLs} baseUrl={settingsBaseUrl} />
        </div>
      </div>
    </div>
  );
}
