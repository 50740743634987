import React, { useEffect, useState } from 'react'
import { getAllRequests, getCallReqeustedNotes, saveCallRequestedNote, updateCallRequestedNote, updateRemarksByUUID, updateStatusByUUID } from '../../services/private/company.service';
import { createDatewithTime } from '../../helpers/date';
import { toast } from 'react-toastify';
import TooltipText from '../../components/Tooltip';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import { insertCallbackRemarks, updateCustomerCallRequested } from '../../services/private/customerApp.service';
import AddRemarksModal from '../PropacityPlus/Modal/AddRemarksModal';

const statusOption = [
    {
        label: 'Resolved',
        value: 'resolved'
    },
    {
        label: 'Pending',
        value: 'pending'
    }
]

const CallRequests = ({
    callBookingList,
    getAllRequests,
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    leadsCount,
    maxPage,
    callStartIndex
}) => {

    const [showRemarksModal, setShowRemarksModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [remarksList, setRemarksList] = useState([]);

    const handleOnChange = async (uuid, value) => {
        const res = await updateCustomerCallRequested(uuid, { status: value })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAllRequests();
        }
    }

    // const getRemarks = async (uuid) => {
    //     const res = await getCallReqeustedNotes(uuid);
    //     if (res.data.status === 200) {
    //         setRemarksList(res.data.data)
    //         setShowRemarksModal(true);
    //     }
    // }
    const handleChangeRemarks = async (remarks) => {
        // const res = await updateRemarksByUUID(selectedItem.uuid, { remarks });
        const res = await insertCallbackRemarks(selectedItem.uuid, { remark: remarks });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setSelectedItem({});
            setShowRemarksModal(false);
            getAllRequests();
        }
    }

    // const handleUpdateRemark = async (selectedRemark) => {
    //     const res = await updateCallRequestedNote(selectedRemark.uuid, { note: selectedRemark.note })
    //     if (res.data.status === 200) {
    //         toast.success(res.data.message);
    //         setSelectedItem({});
    //         setShowRemarksModal(false);
    //         setRemarksList([])
    //         getAllBookedRequest();
    //     }
    // }

    return (
        <div className="users w-100 h-full flex flex-col">
            <div className="user-table">
                <div className="def-table pr-24 !h-[75vh]">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th> Mobile</th>
                                <th>Status</th>
                                <th>Created On </th>
                                <th>Updated At</th>
                                <th>Callback Purpose</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {callBookingList?.length > 0 ? callBookingList.map((item, index) => {

                                return (
                                    <tr key={item.uuid}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {
                                                (item?.customer_name && item?.customer_name.length > 30) ? <TooltipText title={item?.customer_name}>
                                                    <div
                                                        className='truncate w-[200px]'

                                                    >{item?.customer_name}</div>
                                                </TooltipText> :

                                                    item?.customer_name || '-'
                                            }
                                        </td>
                                        <td>{item?.callback_request_number || item?.customer_mobile || '-'}</td>
                                        <td>
                                            <button
                                                className={`text-capitalize flex items-center ${item.status === 'resolved' ? 'hot-btn' : 'dead-btn'}`}
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"

                                            >
                                                {item.status}
                                                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#ffffff" />
                                                </svg>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                {
                                                    statusOption.map(option => {
                                                        return <a
                                                            key={option.value}
                                                            onClick={() => {
                                                                if (option.value !== item?.status) {
                                                                    handleOnChange(item.uuid, option.value)
                                                                }
                                                            }}
                                                            className={`dropdown-item text-capitalize ${item?.status == option.value ? 'selected' : ''}`}
                                                        >
                                                            {option.label}
                                                        </a>
                                                    })
                                                }
                                            </div>
                                        </td>
                                        <td>{item?.created_on ? createDatewithTime(item.created_on) : '-'}</td>
                                        <td>{item?.updated_on ? createDatewithTime(item.updated_on) : '-'}</td>
                                        <td>{item?.callback_purpose || '-'}</td>
                                        <td>
                                            {
                                                item.remarks && <TooltipText title={item.remarks}>
                                                    <div
                                                        className='truncate w-[200px]'
                                                    // onClick={() => {
                                                    //     setSelectedItem(item)
                                                    //     getRemarks(item.uuid)
                                                    // }}
                                                    >{item.remarks}</div>
                                                </TooltipText>
                                            }
                                            {
                                                !item.remarks && <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                    setSelectedItem(item)
                                                    setShowRemarksModal(true)
                                                    // setShowRemarksModal(true);
                                                }}>
                                                    + Add
                                                </span>
                                            }
                                        </td>
                                        {/* <td className="dropdown view-more ">
                                            <button
                                                className="bg-tr border-0"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                        fill="#B5B5BE"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-right w-auto"
                                                aria-labelledby="dropdownMenuButton"

                                            >

                                            </div>

                                        </td> */}
                                    </tr>
                                )
                            }) : null}
                        </tbody>
                    </table>
                </div>
                <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0'
                />
            </div>
            {/* <UserLimitExeedModal show={false} /> */}
            {
                showRemarksModal && <AddRemarksModal
                    show={showRemarksModal}
                    closeModal={() => {
                        setSelectedItem({})
                        setShowRemarksModal(false)
                        setRemarksList([]);
                    }}
                    item={selectedItem}
                    handleSubmit={handleChangeRemarks}
                    remarksList={remarksList}
                    setRemarksList={setRemarksList}
                />
            }
        </div>
    )
}

export default CallRequests