import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TooltipText from '../../components/Tooltip'
import { useHistory } from 'react-router-dom'
import InputSelect from '../../components/InputGroup/InputSelect';
import Consumer from '../../helpers/context';
import { ReactComponent as ExpiryInfoIcon } from '../../assets/icons/expiry_info.svg';
import { ReactComponent as RedInfoIcon } from '../../assets/icons/red_info.svg';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';

const SVTYPES = {
    offline: 'Onsite',
    online: 'Video Call'
}

const getClassName = (exist) => {
    switch (exist) {
        case 'yes':
            return 'hot-btn'
        case 'no':
            return 'dead-btn'
    }
}

const RenderTableData = ({
    item,
    lead,
    setSelectedImg,
    setShowLightModal,
    allUsers,
    handleCheckLeadExist,
    handleSendInvite,
    handleUpdateAssignedTO,
}) => {
    const [show, setShow] = useState(false);

    const target = useRef(null);
    const history = useHistory();

    let { allowedPermissions, userProfile } = useContext(Consumer);

    const handleOutsideClick = (e) => {
        if (target.current && !target.current.contains(e.target)) {
            setShow(false);

        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, []);

    const rendering = () => {
        switch (item.label) {
            case 'Name':
                return <td className='sticky-td'>
                    <div className="text-capitalize z-10 flex items-center">
                        {lead.customer_name?.length > 50 ? <div className='w-[100px] truncate'>
                            <TooltipText title={lead.customer_name}>
                                {lead.customer_name || '-'}
                            </TooltipText>
                        </div> : lead.customer_name}
                        {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <Button className='bg-transparent border-0' ref={target} onClick={() => {
                            setShow(!show)
                        }}>
                            <ExpiryInfoIcon />
                        </Button>}
                        <Overlay target={target.current} show={show} placement="right">
                            {({
                                placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div
                                    {...props}
                                    style={{
                                        position: 'absolute',
                                        top: '54px',
                                        left: '-12px',
                                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                        color: 'white',
                                        borderRadius: 3,
                                        ...props.style,
                                    }}
                                    className="info_box !border !border-red !rounded-lg !px-2.5 !py-1.5 !top-[54px] !-left-3"
                                >
                                    <div className="notes drop-notes flex items-center ">
                                        <div className="left-icon active:!bg-transparent">
                                            <RedInfoIcon />
                                        </div>
                                        <div className="right-text !ml-2">
                                            <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-end !mt-2'>
                                        <button
                                            className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                            onClick={() => handleSendInvite(lead)}
                                        >
                                            Invite CP
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Overlay>
                        {/* {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <ExpiryInfoIcon />
                            </button>
                            <div className="dropdown-menu info_box !border !border-red" aria-labelledby="dropdownMenuButton">

                                <div className="notes drop-notes items-center ">
                                    <div className="left-icon active:!bg-transparent">
                                        <RedInfoIcon />
                                    </div>
                                    <div className="right-text">
                                        <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                    </div>
                                </div>
                                <div className='w-full flex justify-end !mt-2'>
                                    <button
                                        className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                        onClick={() => handleSendInvite(lead)}
                                    >
                                        Invite CP
                                    </button>
                                </div>
                            </div>
                        </div>} */}
                    </div>
                </td>
            case 'Mobile No.':
                return <td>{lead?.customer_mobile || '-'}</td>
            case 'Email':
                return <td>{lead?.customer_email || '-'}</td>
            case 'Scheduled Date':
                return <td>{lead?.schedule_date ? moment(lead?.schedule_date).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'SV Type':
                return <td className='capitalize'>{lead?.sv_type || '-'}</td>
            case 'CP Firm':
                return <td>
                    {/* <button onClick={() => {
                    history.push(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                }}>
                    {lead.company_name || '-'}
                </button> */}
                    {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID &&
                        userProfile?.allowed_modules?.includes('plus_app_management') &&
                        allowedPermissions?.includes('plus_app_manage_users') && lead?.company_name ? <>
                        <button onClick={() => {
                            if (lead.company_name) {
                                window.open(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                            }
                        }}>
                            {lead?.company_name?.length > 20 ?
                                <TooltipText title={lead?.company_name}>
                                    <div className='w-[100px] truncate'>
                                        {lead?.company_name}
                                    </div>
                                </TooltipText>
                                : lead?.company_name
                            }
                        </button>
                    </> :
                        (lead?.broker_company_name || lead?.company_name) && <div className='flex'>
                            {(lead?.broker_company_name || lead?.company_name)?.length > 20 ?
                                <TooltipText title={lead?.broker_company_name || lead?.company_name}>
                                    <div className='w-[100px] truncate'>
                                        {lead?.broker_company_name || lead?.company_name}
                                    </div>
                                </TooltipText>
                                : lead?.broker_company_name || lead?.company_name}

                            {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <ExpiryInfoIcon />
                                </button>
                                <div className="dropdown-menu info_box !border !border-red" aria-labelledby="dropdownMenuButton">

                                    <div className="notes drop-notes items-center ">
                                        <div className="left-icon active:!bg-transparent">
                                            <RedInfoIcon />
                                        </div>
                                        <div className="right-text">
                                            <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-end !mt-2'>
                                        <button
                                            className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                            onClick={() => handleSendInvite(lead)}
                                        >
                                            Invite CP
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    }
                    {
                        (!lead?.company_name && !lead?.broker_company_name && '-')
                    }
                </td>
            case 'CP User':
                return <td>{lead?.broker_name || '-'}</td>
            case 'CP Owner Mobile':
                return <td>{lead.company_owner_mobile || '-'}</td>
            case 'CP User Mobile':
                return <td>{lead.broker_mobile || '-'}</td>
            case 'CP Email':
                return <td>
                    {(lead?.company_owner_email && lead?.company_owner_email?.length > 20) ?
                        <TooltipText title={lead?.company_owner_email}>
                            <div className='w-[100px] truncate'>
                                {lead?.company_owner_email}
                            </div>
                        </TooltipText>
                        : (lead?.company_owner_email || '-')}
                    {/* {lead?.company_owner_email || '-'} */}
                </td>
            case 'CP User Registered':
                return <td>{lead.broker_company_id ? 'Yes' : 'No'}</td>
            case 'Project':
                return <td>{lead?.project_name || '-'}</td>
            case 'Remarks':
                return <td>
                    {lead?.remarks ? <TooltipText title={lead?.remarks}>
                        <div className='w-[200px] truncate'>
                            {
                                lead?.remarks
                            }
                        </div>
                    </TooltipText> : '-'}
                </td>
            case 'Lead Creation Date':
                return <td>{lead?.created_lead_time ? moment(lead?.created_lead_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'Lead Creation':
                return <td>
                    {lead?.create_lead_by ? <a className={`border text-textblack block !w-[117px] !py-2 px-3 rounded-lg hover:bg-black700/10  `} onClick={() => {
                        window.open(`/leads/${lead.lead_uuid}`)
                    }}>
                        View Lead
                    </a> :
                        <a className={`border border-primary text-primary !py-2 px-3 rounded-lg hover:bg-primary/10`} onClick={() => {
                            handleCheckLeadExist(lead, 'triple')
                        }}>
                            Create Lead
                        </a>
                    }
                </td>
            case 'Lead Created By':
                return <td>{lead?.create_lead_by || '-'}</td>
            case 'Address':
                return <td>
                    {lead?.customer_address ? <TooltipText title={lead?.customer_address}>
                        <div className='w-[200px] truncate'>
                            {
                                lead?.customer_address
                            }
                        </div>
                    </TooltipText> : '-'}
                </td>
            case 'Profession':
                return <td>{lead?.customer_profession || '-'}</td>
            case 'Company':
                return <td>{lead?.customer_company || '-'}</td>
            case 'Designation':
                return <td>{lead?.customer_designation || '-'}</td>
            case 'Budget':
                return <td>{lead?.investement || '-'}</td>
            case 'View Photo':
                return <td>
                    {lead.image ? <a className={`border text-textblack block w-max !py-2 px-3 rounded-lg hover:bg-black700/10  `}
                        onClick={() => {
                            setSelectedImg(lead);
                            setShowLightModal(true)
                        }}
                    >
                        View
                    </a> : '-'}
                </td>
            case 'Purpose':
                return <td className='capitalize '>{lead?.purpose || '-'}</td>
            case 'Possession Preference':
                return <td>{lead?.possession_pref || '-'}</td>
            case 'Reference Name':
                return <td>{lead.reference_name || '-'}</td>
            case 'Reference Mobile':
                return <td>{lead.reference_mobile || '-'}</td>
            case 'Reference Society':
                return <td>{lead.reference_society || '-'}</td>
            case 'Reference Flat No':
                return <td>{lead.reference_flat_no || '-'}</td>
            case 'Alt. Mobile No.':
                return <td>{lead?.customer_alternate_number || '-'}</td>
            case 'Closing Manager':
                return <td>
                    <div className='w-[220px]'>
                        {/* <InputSelect
                            placeholder={'Select Closing Manager'}
                            name='assignTo'
                            options={allUsers}
                            value={lead.closing_manager ? { label: lead.closing_manager, value: lead.closing_manager_id } : ''}
                            onChange={(e) => {
                                // const leadList = [...leads]
                                // leadList[index]['assigned_to'] = e.value
                                // setEvents(leadList)
                                if (lead.closing_manager) {
                                    handleUpdateAssignedTO(lead.uuid, e.value)
                                } else {

                                    handleCheckLeadExist(lead, 'table', e.value)
                                }
                            }}
                            isMulti={false}
                        /> */}
                        <div className={`assigned-drop w-full`}>
                            {/* Selected */}
                            <button
                                className={`outline-leads w-full`}
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span>
                                    {lead.closing_manager ? lead.closing_manager : 'Select Closing Manager'}
                                </span>
                                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#44444f" />
                                </svg>
                            </button>
                            <div className="dropdown-menu h-[200px] !w-[220px] overflow-y-auto" aria-labelledby="dropdownMenuButton">
                                {
                                    allUsers.map(item => {

                                        return <a
                                            key={item.value}
                                            onClick={() => {
                                                if (lead.closing_manager) {
                                                    handleUpdateAssignedTO(lead.uuid, item.value)
                                                } else {

                                                    handleCheckLeadExist(lead, 'table', item.value)
                                                }

                                            }}
                                            className={`dropdown-item text-capitalize ${lead?.closing_manager_id == item.value ? 'selected' : ''}`}
                                        // disabled={data?.approval_status === 'pending'}
                                        >
                                            {item.label}
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </td>
            case 'Sourcing Manager':
                return <td>
                    {lead?.sourcing_manager || '-'}
                </td>
            case 'Lead Exist':
                return <td>
                    <span className={`${(lead?.is_lead_existed || lead?.lead_uuid) ? getClassName('yes') : getClassName('no')} `} >
                        {(lead?.is_lead_existed || lead?.lead_uuid) ? 'Yes' : 'No'}
                    </span>
                </td>
            case 'Created lead time':
                return <td>{lead?.created_lead_time ? moment(lead?.created_lead_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'Source':
                return <td>{lead?.about_project || '-'}</td>
            case 'Completed Time':
                return <td>{lead?.completed_time ? moment(lead?.completed_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'Completed By':
                return <td>{lead?.completed_by || '-'}</td>
            case 'Preference':
                return <td>{lead?.preference || '-'}</td>
        }
    }

    return <>{rendering()}</>;
}

export default RenderTableData;
